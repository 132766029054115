<template>
  <div id="visit-us">
    <h1 style="font-weight: bold; color: white;">Visit Us</h1>
    <b-card-group>
      <b-card class="card-background col-3 no-border">
      </b-card>
      <b-card
              class="card-background col-sm-6 no-border"
              img-src="https://i.imgur.com/QkEWt8P.png"
              img-alt="Batavia Restaurant"
      >
        <b-card-text style="color: white">
          <div style="color: #CBA661">Restaurant</div>
          <address>
            <div style="font-weight: bold">Jan van Galenstraat 94</div>
            <div style="font-weight: bold">1056 CD Amsterdam</div>
            <div style="font-weight: bold;"><a href="tel:+31207896547" style="color: #CBA661">+31 (0)20 789 65 47</a></div><br>
            <div style="font-weight: bold">Open daily: 12.00 - 21.00</div>
          </address>
        </b-card-text>
      </b-card>

      <b-card class="card-background col-3 no-border">
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Visit Us',
      titleTemplate: '%s ← Batavia Amsterdam',
      meta: [
        {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {name: 'description', content: 'Come and visit us'}
      ],
      link: [
        {rel: 'canonical', href: 'https://www.bataviamsterdam.com/visit-us'}
      ]
    }
  }
</script>

<style scoped>
  @media only screen and (max-device-width: 568px) {
    #visit-us {
      margin-bottom: 0em;
    }
  }

  @media only screen and (min-device-width: 569px) and (orientation: portrait) {
    h1 {
      margin-bottom: 4em;
    }
    #visit-us {
      margin-bottom: 12em;
    }
  }

  @media only screen and (min-device-width: 569px) and (orientation:landscape) {
    #visit-us {
      margin-bottom: 1em;
    }
  }

  .card-background {
    background-color: #121521;
  }

  .no-border {
    border: none;
  }
</style>